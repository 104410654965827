<!--404页面组件-->
<template>
  <div id="notFound">
    <!-- <div class="notFound_nav">
      <img class="notFound_img" src="../../assets/img/404.jpg" alt="404">
      <p class="notFound_txt">抱歉！您访问的页面没有找到。</p>
    </div> -->
    <div class="one">
      <img src="@/assets/img/noData.png" alt="" style="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "notFound"
};
</script>

<style scoped>
#notFound {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 12px 12px;
  text-align: center;
}

#notFound .notFound_nav {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
}

#notFound .notFound_img {
  max-width: 100%;
  margin: 5% auto;
}

#notFound .notFound_txt {
  text-align: center;
  font-size: 36px;
}
#notFound .one {
  display: flex;
  align-items: center;
  height: 100%;
}
#notFound .one img {
  text-align: center;
  align-items: center;
  width: 242px;
  height: 165px;
  display: block;
  margin: 0 auto;
}
</style>
